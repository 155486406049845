.App {
  text-align: center;
  background-color: #212121;
  overflow: hidden;
}

.App::before {
  content: "";
  position: absolute;
  top: -50%;
  left: 0%;
  width: 100%;
  height: 200%;
  background: radial-gradient(circle at center top, purple 20%, cyan 45%, yellow 55%, transparent 80%);
  mix-blend-mode: screen;
  opacity: 0.3;
  z-index: 0;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.content-overlay {
  position: relative;
  background-color: rgba(0, 0, 0, 0); /* 设置背景为透明 */
  z-index: 1; /* 确保覆盖层位于背景上方 */
}